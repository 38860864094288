/** @jsx jsx */
import React from "react"
import { Box, Flex, Heading, jsx } from "theme-ui"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import PostTemplate from "../templates/post"
import { Link } from "gatsby"

import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <Flex
      sx={{ justifyContent: "center", alignItems: "center", minHeight: "50vh" }}
    >
      <Box>
        <Heading mb={3} sx={{ fontSize: 5, textAlign: "center" }}>
          Page not found!
        </Heading>
        <Box mb={3} sx={{ fontSize: 3, textAlign: "center" }}>
          <Link to="/">Go To Home</Link>
        </Box>
      </Box>
    </Flex>
  </Layout>
)

// If an unpublished `post` document is previewed, PostTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    post: PostTemplate,
  },
})
